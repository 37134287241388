/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
// import styled from 'styled-components';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Lead from '../components/Common/Lead';
import Products from '../components/Home/Products';
import Services from '../components/Common/Services';

const Section = styled.section`
  .columns {
    display: grid;

    ul {
      margin: 0 0 3rem;

      li {
        + li {
          margin-top: 8px;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    display: block;
    margin: 0 auto;
    max-width: 700px;
    text-align: center;
  }

  .grid-square {
    display: grid;
    gap: 8rem 5rem;
    grid-template-columns: repeat(1, 1fr);
    margin: 5rem 0;

    img {
      margin: 0 auto;
      max-width: 300px;
    }

    @media only screen and (min-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  table {
    th {
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(7),
      &:nth-child(8) {
        text-align: left;
      }
    }
    tbody {
      tr {
        &:nth-child(1) {
          td:nth-child(1) {
            background: gold;
            color: #000;
          }
        }
        &:nth-child(2) {
          td:nth-child(1) {
            background: #919191;
          }
        }
        &:nth-child(3) {
          td:nth-child(1) {
            background: #763a13;
          }
        }

        &:nth-child(-n + 3) {
          td {
            &:nth-child(2) {
              font-weight: 600;
            }
          }
        }

        &:nth-child(even) {
          td {
            background: #361532;
          }
        }

        &:last-child {
          td:first-child {
            visibility: hidden;
          }
        }

        &:last-child {
          td {
            border-top: 4px solid rgba(199, 87, 132, 0.25);
          }
        }
      }
    }
  }

  @media only screen and (min-width: 820px) {
    margin-top: -20px;

    .columns {
      align-items: center;
      grid-gap: 50px;
      grid-template-columns: repeat(2, 1fr);
      margin: 0 0 40px;
    }
  }
`;

const DataTable = [
  {
    price: '383 600',
    unit: '10 złotych',
    year: '1925',
    name: 'Bolesław Chrobry',
    state: '-',
    metal: 'złoto',
    auction_house: 'WDA',
    auction_nr: '7',
    url: 'https://onebid.pl/pl/monety-r-10-zlotych-1925-boleslaw-chrobry-proba-w-zlocie-stempel-gleboki-unikat/221284',
    date: '2018.03.17',
  },
  {
    price: '318 000',
    unit: '20 złotych',
    year: '1925',
    name: 'Głowa kobiety',
    metal: 'złoto',
    state: 'NGC MS66',
    auction_house: 'Niemczyk',
    auction_nr: '26',
    url: 'https://archiwum.niemczyk.pl/product/283087/proba-zloto-20-zlotych-1925-glowa-kobiety-polonia-ngc-ms66-max',
    date: '2020.10.17',
  },
  {
    price: '264 500',
    unit: '2 grosze',
    year: '1923',
    name: '',
    metal: 'złoto',
    state: '-',
    auction_house: 'Niemczyk',
    auction_nr: '3',
    url: 'https://archiwum.niemczyk.pl/product/120944/ii-rp-2-grosze-1923-proba-zloto-unikat',
    date: '2012.10.21',
  },
  {
    price: '264 000',
    unit: '100 marek',
    year: '1922',
    name: 'Piłsudski',
    metal: 'złoto',
    state: 'NGC MS62',
    auction_house: 'Niemczyk',
    auction_nr: '21',
    url: 'https://archiwum.niemczyk.pl/product/263781/ii-rp-proba-zloto-100-marek-1922-pilsudski-ngc-ms62-max-odmiana-z-napisem-proba-unikat',
    date: '2019.10.26',
  },
  {
    price: '216 000',
    unit: '5 złotych',
    year: '1930',
    name: 'Nike (Jackowski)',
    metal: 'srebro',
    state: 'NGC MS64',
    auction_house: 'Jerzykowski',
    auction_nr: '1',
    url: 'https://onebid.pl/pl/monety-proba-5-zlotych-1930-nike-wizyta-ministra-jackowskiego-w-brukseli-srebro/2365763',
    date: '2024.09.14',
  },
  {
    price: '216 000',
    unit: '100 złotych',
    year: '1925',
    name: 'Kopernik',
    metal: 'złoto',
    state: '-',
    auction_house: 'Niemczyk',
    auction_nr: '17',
    url: 'https://archiwum.niemczyk.pl/product/244001/ii-rp-proba-zloto-100-zlotych-1925-kopernik-drugi-znany-egzemplarz',
    date: '2018.10.27',
  },
  {
    price: '171 100',
    unit: '2 złote',
    year: '1924',
    name: 'Kobieta i kłosy - ESSAI',
    metal: 'srebro',
    state: 'NGC MS66',
    auction_house: 'Stary Sklep',
    auction_nr: '11',
    url: 'https://onebid.pl/pl/monety-proba-2-zlote-kobieta-i-klosy-1924-essai-ex-karolkiewicz/2435407',
    date: '2024.10.12',
  },
  {
    price: '153 400',
    unit: '5 złotych',
    year: '1925',
    name: 'Konstytucja',
    metal: 'srebro',
    state: 'NGC MS62',
    auction_house: 'Stary Sklep',
    auction_nr: '14',
    url: 'https://onebid.pl/pl/monety-proba-5-zlotych-konstytucja-1925-unikat-jedyna-znana/2104667',
    date: '2024.02.10',
  },
  {
    price: '110 920',
    unit: '5 złotych',
    year: '1927',
    name: 'Nike',
    metal: 'srebro',
    state: 'NGC MS62',
    auction_house: 'Stary Sklep',
    auction_nr: '9',
    url: 'https://onebid.pl/pl/monety-proba-5-zlotych-nike-1927-srebro-wyjatkowa/1906949',
    date: '2023.10.14',
  },
  {
    price: '102 000',
    unit: '5 złotych',
    year: '1925',
    name: 'Konstytucja (SW i WG)',
    metal: 'srebro',
    state: '-',
    auction_house: 'Niemczyk',
    auction_nr: '43',
    url: 'https://archiwum.niemczyk.pl/product/372015/ii-rp-proba-srebro-konstytucja-5-zlotych-1925-monogramy-sw-i-wg-piekna-i-rzadka',
    date: '2024.03.17',
  },
  {
    price: '14 400',
    unit: '1 grosz<sup>*</sup>',
    year: '',
    name: '',
    metal: 'tektura(!)',
    state: '',
    auction_house: 'Jerzykowski',
    auction_nr: '1',
    url: 'https://onebid.pl/pl/monety-1-grosz-papierowy-wzor-tektura/2362014',
    date: '2024.09.14',
  },
];

const siteMetadata = {
  title: 'Monety II RP - skup i wycena polskich monet obiegowych',
  description: 'Chętnie skupujemy polskie monety obiegowe z okresu II RP. Skupujemy monety srebrne, złote monety, monety kolekcjonerskie, monety próbne, monety obiegowe.',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/skup-monet-ii-rp/',
  locale: 'pl_PL',
};

const Coins2rpPage = ({ data }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <h1>Monety II Rzeczypospolitej</h1>

    <Lead
      maxWidth="850px"
      desc="Monety z okresu międzywojennego są obecnie jednym z najczęściej kolekcjonowanych tematów przez polskich numizmatyków."
    />

    <Section style={{ marginTop: '4rem' }}>
      <div className="columns">
        <div className="col--6">
          <p>Monety II RP, wybite w latach 1918-1939, cieszą się niesłabnącym zainteresowaniem kolekcjonerów z kilku istotnych powodów.</p>
          <ul>
            <li>Charakteryzują się one wyjątkowym wyglądem, który łączy w&nbsp;sobie elementy tradycji i&nbsp;nowoczesności, często z&nbsp;pięknymi motywami narodowymi i&nbsp;historycznymi.</li>
            <li>Są stosunkowo łatwo dostępne, co sprawia, że&nbsp;początkujący kolekcjonerzy mogą je nabywać bez większych trudności.</li>
            <li>Wiele z&nbsp;tych monet wykonano z&nbsp;cennego kruszcu, jakim jest srebro, co dodaje im dodatkowej wartości zarówno materialnej, jak i&nbsp;sentymentalnej.</li>
            <li>Wreszcie, szeroki zakres cen monet z&nbsp;tego okresu pozwala na kolekcjonowanie ich przez osoby o&nbsp;różnym budżecie, od niewielkich inwestycji po zakupy rzadkich i&nbsp;drogocennych egzemplarzy.</li>
          </ul>

          <p>Wszystkie te czynniki razem sprawiają, że monety II&nbsp;RP są niezwykle atrakcyjne i&nbsp;chętnie zbierane przez kolekcjonerów.</p>
        </div>

        <div className="col--6">
          <figure>
            <GatsbyImage
              image={getImage(data.klamry)}
              alt="10 złotych 1934 - próba, awers i rewers"
            />
            <figcaption>10 złotych 1934 Klamry<br />bardzo rzadka, próbna moneta</figcaption>
          </figure>
        </div>
      </div>

      {/* <figure style={{ maxWidth: '900px', marginBottom: '80px' }}>
        <GatsbyImage
          image={getImage(data.philadelphia)}
          alt="mennica filadelfia"
        />
        <figcaption>Sekretarz poselstwa polskiego w Waszyngtonie p. Leon Orłowski ogląda w towarzystwie dyrektora Mennicy Stanów Zjednoczonych w Filadelfii pierwszą odbitą tam polską srebrną monetę dwuzłotową.</figcaption>
      </figure> */}
    </Section>

    <Section style={{ maxWidth: '900px', marginBottom: '80px' }}>
      <h2>Próbne monety II RP</h2>

      <p>Próbne monety z okresu II Rzeczypospolitej Polskiej są interesującym i wartościowym elementem numizmatyki. Monety te były wybijane w celu testowania wzorów, technologii produkcji oraz materiałów, zanim rozpoczęła się ich masowa produkcja. Często różniły się one od wersji ostatecznych pod względem projektu, metalu czy napisu.</p>

      <figure>
        <GatsbyImage
          image={getImage(data.monety_probne)}
          alt="3 złote monety próbne II RP"
        />
        <figcaption>3 najdroższe monety próbne II RP<br />10 złotych 1925 - Bolesław Chrobry, 20 złotych 1925 - Głowa Kobiety, 2 grosze 1923</figcaption>
      </figure>

      <p>Próbne monety II RP przeważnie były wybijane w niskich nakładach, co czyni je rzadkimi i poszukiwanymi przez kolekcjonerów. Wśród najbardziej znanych można wymienić próbne srebrne monety z wizerunkiem marszałka Józefa Piłsudskiego, które są obecnie bardzo cenione na rynku numizmatycznym.</p>

      <figure className="text-center">
        <GatsbyImage
          image={getImage(data.proba_klipa)}
          alt="Próbna moneta w kształcie klipy z wizerunkiem Józefa Piłsudskiego"
        />
        <figcaption>10 złotych 1934 - próbna moneta w kształcie klipy.<br />Rewers z wizerunkiem Józefa Piłsudskiego, natomiast na awersie znajduje się orzeł strzelecki (źródło: coinstrail.com)</figcaption>
      </figure>

      <h3 className="text-center" style={{ margin: '10rem 0 3rem' }}>
        TOP 10 najdroższych próbnych monet&nbsp;II&nbsp;RP<br />
      </h3>

      <div className="scroll-box">
        <table>
          <thead>
            <tr>
              <th>Lp.</th>
              <th>Cena</th>
              <th>Nominał</th>
              <th>Rok</th>
              <th>Moneta</th>
              <th>Metal</th>
              <th>Stan</th>
              <th title="Dom Aukcyjny">DA</th>
              <th>Aukcja</th>
              <th>Data</th>
            </tr>
          </thead>

          <tfoot>
            <tr rowSpan="2">
              <td colSpan="10">
                <p><sup style={{ marginLeft: '-9px  ' }}>*</sup>1 grosz - pozycja poza zestawieniem, ale walor godny odnotowania, ze względu na materiał, z którego został zrobiony. (<em>Parchimowicz 194)</em></p>
                <p>Źródło: <em>niemczyk.pl</em>, <em>onebid.pl</em></p>
                <p>Aktualizacja: <em>2024.09.14</em></p>
              </td>
            </tr>
          </tfoot>

          <tbody>
            {DataTable.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td>{item.price} zł</td>
                <td dangerouslySetInnerHTML={{ __html: item.unit }}></td>
                <td>{item.year}</td>
                <td>{item.name}</td>
                <td>{item.metal}</td>
                <td>{item.state}</td>
                <td>{item.auction_house}</td>
                <td>{item.auction_nr}</td>
                <td><a href={item.url} rel="nofollow">{item.date}</a></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <p>Jak można zauważyć, <em>Dom Aukcyjny Michał Niemczyk</em> zdominował ranking. W&nbsp;zestawieniu nie wzięły udziału zagraniczne domy aukcyjne takie jak np. <em>Heritage Auctions</em>, <em>Stack's Bowers</em> czy <em>Spink UK</em>.
      </p>
    </Section>

    <Section className="section-text" style={{ border: '0' }}>
      <h2>Najrzadsze monety z&nbsp;okresu międzywojennego</h2>

      <p>Niektóre monety obiegowe II Rzeczypospolitej, ze względu na ich rzadkość oraz stan zachowania, osiągają na aukcjach bardzo wysokie ceny.</p>

      <div className="grid-square">
        <div>
          <GatsbyImage
            image={getImage(data.pieciogroszowka)}
            alt="5 groszy 1934"
          />
          <h3>5 groszy 1934</h3>

          <p>Wśród monet groszowych, <em>5 groszy z 1934</em> roku jest szczególnie poszukiwana. Jest to najrzadsza moneta groszowa z okresu międzywojennego. W 2023 roku egzemplarz tej monety w stanie MS65 w odmianie "red" został sprzedany za 48.600 zł.<br />(źródło: <a href="https://archiwum.niemczyk.pl/product/358010/5-groszy-1934-najrzadsze-pcgs-ms65-rd-max-ideal-w-najbardziej-pozadanym-kolorze-rd" title="5 groszy 1934" rel="nofollow">Aukcja 40</a> - <em>niemczyk.pl</em>)</p>
        </div>

        <div>
          <GatsbyImage
            image={getImage(data.birmingham)}
            alt="2 złote 1924 - Birmingham"
          />
          <h3>2 złote 1924 -&nbsp;mennica&nbsp;Birmingham</h3>

          <p>Monety z 1924 roku, wybite w mennicy <em>Birmingham</em>, oznaczone charakterystyczną literką <em>"H"</em> na awersie, to kolejne cenne egzemplarze wśród monet obiegowych. Moneta bardzo poszukiwana przez kolekcjonerów, praktycznie w każdym stanie zachowania.</p>
        </div>

        <div>
          <GatsbyImage
            image={getImage(data.pilsudski)}
            alt="2 złote 1936"
          />
          <h3>2 złote 1936 - Piłsudski</h3>

          <p>Moneta z 1936 roku z wizerunkiem Józefa Piłsudskiego to jedna z bardziej poszukiwanych przez kolekcjonerów. Jest symbolem honoru i dumy narodowej, a jej wygląd jest uznawany za jeden z najpiękniejszych w całej serii monet&nbsp;II&nbsp;RP.</p>
        </div>

        <div>
          <GatsbyImage
            image={getImage(data.sztandar)}
            alt="Próbna moneta w kształcie klipy z wizerunkiem Józefa Piłsudskiego"
          />
          <h3>5 złotych Sztandar -&nbsp;głęboki&nbsp;stempel</h3>

          <p>Moneta okolicznościowa, upamiętniała 100-lecie Powstania Listopadowego. Moneta występuję w dwóch odmianach, bita stemplem płytkim i głębokim. </p>
        </div>
      </div>

      <GatsbyImage
        image={getImage(data.nike)}
        alt="Próbna moneta w kształcie klipy z wizerunkiem Józefa Piłsudskiego"
      />

      <h3>5 złotych 1932 - Nike</h3>

      <p>Najrzadszą monetą obiegową II RP jest <strong>5 złotych 1932 z wizerunkiem Nike</strong>. Jej nakład wynosił jedynie 104.000 sztuk (źródło: J. Chałupski). W 1932 roku zmieniono parytet srebrnych monet obiegowych i wprowadzono nowe 5&nbsp;złotych, które były mniejsze i lżejsze. Jak można przeczytać w katalogu Jerzego Chałupskiego:</p>

      <cite style={{ maxWidth: '750px' }}>
        <div>"Po wycofaniu tej emisji z&nbsp;obiegu, mennica warszawska przetopiła 22,8 miliona pięciozłotówek z&nbsp;łącznego nakładu wynoszącego 26,7 mln. Przetopiono zdecydowaną większość monet z&nbsp;rocznika 1932, stąd jego wielka rzadkość i&nbsp;wysokie notowania rynkowe [...]".</div>
        <br />
        <em>Jerzy Chałupski - "Specjalizowany katalog monet polskich XX i XXI w." część II, s. 210</em>
      </cite>

      <p>W 2023 roku moneta 5 złotych 1932 Nike z notą gradingową NGC MS61 została sprzedana za 150.000&nbsp;zł.<br />
        (źródło: <a href="https://archiwum.niemczyk.pl/product/357994/5-zlotych-1932-nike-ngc-ms61-najrzadsza-moneta-obiegowa-ii-rp-piekna-i-rzadka" title="5 złotych 1932 Nike" rel="nofollow">Aukcja 40</a> - <em>niemczyk.pl</em>)
      </p>
    </Section>

    <Products />

    <Section className="section-text section-text--no-border">
      <p>***</p>

      <p>Monety II Rzeczypospolitej to nie tylko elementy systemu monetarnego, ale także świadectwa burzliwej historii Polski i jej drogi do niepodległości. Ich różnorodność, jakość wykonania oraz często unikalne wzornictwo sprawiają, że są one obiektem pożądania wielu kolekcjonerów. Dla pasjonatów numizmatyki, monety te stanowią nie tylko cenny nabytek, ale także niezwykłą podróż w czasie, do lat, kiedy Polska budowała swoją tożsamość na nowo.</p>

      <p>Jeśli jesteś zainteresowany <Link to="/wycena-monet-i-banknotow/">wyceną lub sprzedażą monet</Link> z tego okresu, zapraszamy do <Link to="/kontakt/">kontaktu</Link>.<br />Specjalizujemy się w skupie monet II RP, a nasze doświadczenie i pasja gwarantują satysfakcję z każdej transakcji.</p>
    </Section>

    <Services />
  </>
);

export const query = graphql`
  query {
    klamry: file(relativePath: {regex: "/10-zlotych-1934-klamry-proba/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 555
        )
      }
    }
    philadelphia: file(relativePath: {regex: "/mennica-filadelfia/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 900
        )
      }
    }
    monety_probne: file(relativePath: {regex: "/monety-probne-ii-rp/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 900
        )
      }
    }
    proba_klipa: file(relativePath: {regex: "/proba-klipa-10-zlotych-1934-pilsudski-strzelecki/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 700
        )
      }
    }
    pieciogroszowka: file(relativePath: {regex: "/5-groszy-1934/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 300
        )
      }
    }
    birmingham: file(relativePath: {regex: "/2-zlote-1924-birmingham.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 300
        )
      }
    }
    pilsudski: file(relativePath: {regex: "/2-zlote-1936-pilsudski-2/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 300
        )
      }
    }
    sztandar: file(relativePath: {regex: "/5-zlotych-1930-sztandar-gleboki/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 300
        )
      }
    }
    nike: file(relativePath: {regex: "/5-zlotych-1932-nike/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 700
        )
      }
    }
  }
`;

export default Coins2rpPage;
